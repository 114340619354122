import React from "react";
import "../css/styles.css";
import "../css/parallax.css";
import VideoClip1 from "./VideoClip1";

const Nosotros = () => {
  return (
    
    <div className="container-fluid lyrics">
      <section className="parallax-containerH">
      
      <div className="row">
      <h1>¿Quiénes somos?</h1>
        <h1>Condominios inteligentes</h1>
        <p className="text-center fs-5 col">
          El crecimiento de la Ciencia y la Tecnología en Aguascalientes, Intra
          Corporativo forma parte de Investel, un conglomerado de empresas
          expertas en todas las ramas de la ciencia y la tecnología, accediendo
          a catalogo innumerable de especialistas, creciendo y fortaleciendo
          nuestros servicios y soluciones, poniendo en alto a nuestro estado de
          Aguascalientes y muy pronto a México como el mejor a nivel
          latinoamérica.
        </p>
        <div className="row map-responsive">          
          <VideoClip1 />
        </div>
      </div>
      <div className="block"> 
        <p className="text-center fs-5"> En "Condominios Inteligentes", nos esforzamos por ofrecer una atención personalizada a nuestros clientes, 
          brindando asesoramiento experto en la compra, venta o alquiler de propiedades dentro de nuestros condominios.
           Nuestro equipo de profesionales altamente capacitados y comprometidos está siempre disponible para ayudar y 
           garantizar una experiencia satisfactoria.</p></div>

           <h1>Nuestros Valores</h1>    
      <div className="row">
        
        <div className="col-sm OpacBack_2">
          <h1>Mision</h1>
          <p className="text-center fs-5">
          Diseñar, construir y gestionar hogares y condominios inteligentes que brinden a nuestros
           clientes un estilo de vida moderno, cómodo y sostenible
          </p>
        </div>

        <div className="col-sm OpacBack_1">
          <h1>Visión</h1>
          <p className="text-center fs-5">
          Ser reconocidos a nivel nacional e internacional por nuestra capacidad para desarrollar
           proyectos innovadores que transformen la forma en que las personas viven y se relacionan con su entorno.
          </p>
        </div>

        <div className="col-sm OpacBack">
          <h1>Valores</h1>
          <div className="text-center fs-5">
            <p>Innovación y tecnología </p>
            <p>Experiencia y conocimiento  </p>
            <p>Sostenibilidad y eficiencia energética </p>
            <p>Calidad y diseño</p>
          </div>
        </div>
        </div>
        </section>
        <section className="parallax-containerH">
      <h1>¿Por qué nosotros?</h1> 
      <div className="block"> 
        <p className="text-center fs-5  ">Como empresa, nos dedicamos a diseñar, construir y administrar condominios inteligentes 
          que aprovechan al máximo las últimas tendencias en domótica, energía renovable y
          conectividad. Nuestros condominios están equipados con sistemas de automatización para
          controlar la iluminación, la climatización, la seguridad y otros aspectos clave del hogar,
          todo ello desde una aplicación móvil o un controlador centralizado.</p>
          <p className="text-center fs-5">
          "Condominios Inteligentes" se destaca por su enfoque innovador y
          la incorporación de tecnología de vanguardia en sus proyectos. Nuestros hagares como condominios están 
          equipados con sistemas de automatización y domótica, lo que brinda a los residentes
          comodidad, conveniencia y control total sobre su entorno.</p>
        <p className="text-center fs-5"> 
          se compromete con el cuidado del medio ambiente y la eficiencia energética. Sus proyectos incorporan
           soluciones eco-amigables, como paneles solares y sistemas inteligentes de gestión de recursos, lo
            que resulta en ahorros significativos en los costos de operación y una menor huella ecológica.</p>
      </div>
      <span></span>
      </section>
    </div>
  );
};
export default Nosotros;

import React from "react";
import Maps from "./Maps";
import ContactUs from "./Email";
import '../css/styles.css';
import '../css/parallax.css';
const Contactos = () => {
  return (
    <section className="parallax-containerH">
       <div className="conteiner-fluid">         
      <h1>¿Deseas contactarnos?</h1>
      <h4 className="h20">Llena los siguientes datos con tu información, por favor.</h4>
      <ContactUs />
      <p></p>
      <h1 text-align="center">Encuentranos en nuestras oficinas</h1>
      <div align="center">
        <p className="pdireccion">
        1.- Ignacio N. Marín 118, Modelo, 20080 Aguascalientes, Ags.          
        </p>
        <div  className="Map"><Maps />  </div>      
      </div>
    </div></section>
  );
};

export default Contactos;
import React from "react";
const Luminarias = () => {
  return (
    <div id="parallax-world-of-ugg">
      <div className="container-fluid">
        <section>
        <div className="parallax-lumini">
          <h2>Luminarias</h2>
        </div>
        </section>                        
            <section> 
              <div className="block">
             <p className="text-justify fs-5">
             La iluminación es el compañero efímero de la arquitectura. La luz es invisible hasta que toca un objeto o superficie.
              Y el controlar este medio difícil y transitorio es lo que le da al artista de iluminación la capacidad de crear jerarquías, 
             dinámica y ambiente. El diseño de iluminación ha llegado a ser la extensión creativa del diseño arquitectónico,
              mejorando la visibilidad y complementando la forma, programa y color. La experiencia y, por supuesto el talento, 
              crea las pautas de la iluminación que apoya continuamente los objetivos
            </p> 
            </div>
            </section>     
        <details className="style2">
              <summary>ver más</summary>
              <div className="block"> 
                <div className="row" >
                  <div  className="text-justify fs-5">
                  <p> Las luminarias pueden incluir lámparas de techo, apliques de pared, 
                  lámparas de mesa, lámparas de pie, tiras de luces LED, entre otros.
                  Cada tipo de luminaria tiene su función y estilo específico, y se eligen
                  de acuerdo a las necesidades y preferencias de cada espacio.</p>
                  </div> 
                  <div className="text-justify fs-5">
                    <p>  Además de brindar iluminación, las luminarias también pueden contribuir
                    al diseño interior de una casa, agregando un toque decorativo y personalidad
                    al ambiente. Existen luminarias con diferentes diseños, tamaños, colores y 
                    estilos, lo que permite adaptar la iluminación al gusto y la estética de cada
                    persona.</p>
                  </div>
                  <div className="text-justify fs-5"> 
                  <p> Es importante considerar la eficiencia energética al elegir las luminarias,
                  optando por luces LED de bajo consumo que ayuden a reducir el consumo de electricidad 
                  y la huella ambiental.</p>
                </div>
                <div className="row" > 
                <img className="homeimage"
                       src="https://img.freepik.com/foto-gratis/pasillo-piso-hotel_23-2149304103.jpg?w=360&t=st=1688413734~exp=1688414334~hmac=aaddc2b98d4059c483b40d5d15985588a714c58731a26b51108fb6a7bfae35b2"
                        alt="something here" />
               </div>
               </div>
             </div>
            </details>
      </div>
    </div>
  );
};

export default Luminarias;

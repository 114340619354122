import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import "../css/forms.css";

//esto es con Emailjs
export const ContactUs = () => {

  //Email JS start 
  const form = useRef();

  const Email = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_w77gnqc",
        "template_o0a3st5",
        form.current,
        "qBpumzrCeDRVAraTo"
      )
      .then(
        (result) => {
          swal({
            text: "Enviado \\(≧▽≦)/",
            icon: "success",
            timer:"2000"
          });
          console.log(result.text);
          form.current.reset();
        },
        (error) => {
          swal({
            text: "Hubo un error //°n°\\",
            icon: "error",
            timer:"2000"
          });
          console.log(error.text);
        }
      );
  };
  //Email JS start END
  return (
          <form className="container-fluid"  ref={form} onSubmit={Email} >
      <div className="form-group">
            <label htmlFor="validationDefault01" className="col-xs-2 control-label">Nombre</label>    
            <div className="col-xxl">      
            <input  type="text" id="validationDefault01" className="form-control input-xs" required name="user_name" placeholder="Escribe tu nombre" />
          </div>
          </div>
          <div className="form-group">
            <label htmlFor="validationCustom01" className="col-xs-2 control-label"> Email </label>
            <div className="col-xxl">
              <input  className="form-control input-sm col-xs-1" type="email"  name="user_email" placeholder="ejemplo@gmail.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required  />
          </div>
          </div>
          <div className="form-group container-fluid">
          <label className="col-xs-2 control-label" >Mensaje</label>
          <div className="col-xs-10">
          <textarea className="form-control-xs" id="validationTextarea" name="message" placeholder="Teléfono, condiciones de contacto, pregunta o comentarios" />
          </div>
          </div>
        <div className="col-12" >
          <button  className="btn">
            Enviar
          </button>
        </div>        
      </form>    
  );
};
//validation pending....
export default ContactUs;
import React from "react";
import './css/footer.css';

const Footer = () => {
  return (
    <footer>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <h3>Sobre nosotros:</h3>
          <p> La idea de usar en viviendas con alta tecnología, así como bastos dispositivos para hacer la vida más fácil y divertida a todos los que viven en ella.</p>
          <p></p>
          <a href="/">
          <img className="logo" src={require("./pages/img/OGlogo3.png")}alt="imagen aqui"/></a>
        </div>
        <div className="col-md-3">
          <h3>Contactanos</h3>
          <ul className="list-unstyled">
            <li>Av. Las Americas 1701-Piso 4, El dorado, </li>
            <li>Aguascalientes, Ags., 20235 </li>
            <li>Tel: MEX(+52) 449 114 4237</li> 
            <li>  Centro América: (+506) 7286 7555</li>
            <li>Email: info@condominiosinteligentes.com </li>
          </ul>
        </div>
        <div className="col-md-3" align='center'>
          <h3>Siguenos en</h3>
          <ul className="list-unstyled">
            <li><a href="https://www.facebook.com/inteligentescondominios"><i className="gg-facebook"></i></a></li>
            <li><a href="/"><i className="gg-instagram"></i></a></li>
            <li><a href="/"><i className="gg-twitter"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>  
  );
};

export default Footer;

import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import "../css/styles.css";
//21.864222127794502, -102.3052987421825  21.87630340530414, -102.30902431390103
//21.872668537797015, -102.30936738190155
const containerStyle = {
  width: '75vw',
  height: '65vh'
};

const center = {
   lat:21.87630340530414, 
   lng: -102.30902431390103 
};


class Maps extends React.Component {
  
  render() {
    return (
      <div >
        <LoadScript googleMapsApiKey="AIzaSyCH2kRCD1PtSRaV3ZPu9g8wT_8XjCFaXBA">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14.9}
          >        
              <MarkerF
              position={{ lat:21.87630340530414, lng: -102.30902431390103 }}
              title="Oficinas Ignacio N. Marín 118, Modelo, 20080 Aguascalientes, Ags."
            />
          </GoogleMap>
        </LoadScript>
      </div>
    );
  }
}

export default Maps;

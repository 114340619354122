import React from "react";
import "./css/styles.css";
import "./css/parallax.css";

const Home = () => {
  return (
    <div>
      <div id="parallax-world-of-ugg">
        <section>
          <div className="parallax-home">
            <div className=""><h2>CONDOMINIOS INTELIGENTES </h2> </div>            
          </div>
        </section>              
      </div>        
      <div className="container-fluid">
      <br></br>
        <div className="parallax-containerH">
          <div className="grid ">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-lg ">
                  <figure>
                    <img
                      className="homeImg1"
                      alt="Aqui pondria imagen, si tuviera una"
                      src="https://uploads-ssl.webflow.com/5c0923437b3820198bab7be0/620bf9303cc50f0dc3095f20_nFWgU4H8AD7bDTXuvIa4-AoiphxQIkbeKnh0Bat0NhSpLTBS1jb4jRnosK-lD3DimJPStm3WvVeK_0R4542o36QpntJUSihM_lV8Z2Xz8DBduDTdlccvKxIaNlWPEaUJHhPNoHEH.jpeg"
                    />                 
                  </figure>
                </div>
                <div className="col-sm">        
                    <br></br>                  
                  <div className="row">                    
                    <h2 className="blanco">En pocas palabras</h2>
                    <p className="text-justify fs-5 col">
                      Un condominio es inteligente cuando técnicas de uso
                      automatizado en la propiedad (confort, seguridad,
                      comunicación), tambien conocido como donde se podrá
                      automatizar la iluminación, el audio, el vídeo y las
                      persianas motorizadas de su condominio simplificando su
                      vida, desde un acceso a una interfaz única simple, fiable
                      y fácil de usar. La aplicación en su tableta o teléfono
                      móvil le permitirá controlar no solo la iluminación, sino
                      también la música, las películas y las persianas con un
                      solo clic.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item item-3">
              <div className="row">               
                <div className="col-sm">
                  <br></br>
                  
                  <h1 className="blanco3"> ¿Qué los caracteriza? </h1>
                  <p className="text-justify fs-5 col">
                    Los condominios inteligentes cuentan con sistemas de
                    seguridad avanzados, como la entrada sin llave y la
                    vigilancia por video, lo que garantiza la tranquilidad y la
                    seguridad de los residentes. También pueden contar con
                    sistemas de eficiencia energética, como termostatos
                    inteligentes y paneles solares, para reducir los costos de
                    energía y ayudar al medio ambiente. En resumen, un
                    condominio inteligente es un hogar moderno y conectado que
                    proporciona una experiencia de vida más cómoda, segura y
                    eficiente para sus residentes.
                  </p>
                </div>
                <div className="col-lg">
                  <figure>
                    <img
                      className="homeImg1"
                      alt="Aqui pondria imagen, si tuviera una"
                      src="https://img.interempresas.net/fotos/2898886.png"
                    />                   
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

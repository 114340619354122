import React from 'react'
import "../css/styles.css";
//const src = "https://www.youtube.com/embed/d15DP5zqnYE";
const VideoClip1 = () => {
  return (
    <iframe width="560" height="315"
     src="https://www.youtube.com/embed/3InixmPBUtw" 
     title="YouTube video player" frameborder="0" 
     allow="accelerometer; autoplay; clipboard-write; 
     encrypted-media; gyroscope; picture-in-picture; web-share" 
     allowfullscreen>
     </iframe>
  )
}

export default VideoClip1
import { React } from "react";
import "../css/styles.css";
const Seguridad = () => {

  return (
    <div id="parallax-world-of-ugg">
    <div className="container-fluid">
    <section>
        <div className="parallax-seguridad">
          <h2>Seguridad</h2>
        </div>
        </section>
        <section> 
          <div className="block">
            <h3 className="blanco" align="center">Video Vigilancia</h3>           
            <p className="text-justify fs-5">      
              Las mejores tecnologías y capacidades están en soluciones IP,
              varias son sus ventajas como una excelente calidad de imagen,
              colores vivos y niveles muy bajos de ruido, además de todas las
              capacidades de procesamiento de Hik-Central con reconocimiento
              facial, placas, conteo de personas y automatización, todo esto de
              la mano con la tecnología Deep Learning de HikVision
            </p>            
          </div>    
          </section>
        <details className='style2' >
          <summary>ver más</summary>
            <div className="block"> 
            <div className="row" > 
              <div  className="text-justify fs-5" >            
                <p> La meta de brindar un servicio integral de calidad, un servicio
                completo, seguro y transparente, forjando alianzas con especialistas
                en energías renovables, expertos en programación y diseño web,
                maestros en trabajos verticales y mantenimiento, llegando asi a profesionales en
                Seguridad y Sistemas de Rastreo, entre estos sistemas nosostros tenemos:</p>
                </div>
                <div  className="text-justify fs-5" >          
                <p> Video Vigilancia</p>
                <p>Desde instalaciones sencillas con tecnología Turbo HD de 2 y hasta 8mpx, hasta 
                  implementación de cámaras IP 4k, servidores, almacenamiento masivo, centrales 
                  de monitoreo con video wall, sistemas inteligentes y automatizados con reconocimiento
                  facial, lectura de placas o biométricos. </p>
              </div>
              <div className="row">
              <img className="homeImg"
                       src="https://img.freepik.com/foto-gratis/tecnologia-seguridad-cctv-icono-candado-remezcla-digital_53876-104935.jpg?w=996&t=st=1688414046~exp=1688414646~hmac=c2ee782082d06b1230d0eb4d5c2b66e173a9fb9c7e0af5b0a3a0733d24370d15" 
                       alt="something here" />
              </div> 
            </div>
          </div>
        </details>  
      </div>
</div>
  );
};

export default Seguridad;

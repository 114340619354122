import React from 'react'

const Paneles = () => {
  return (
    <div id="parallax-world-of-ugg">
    <div className='container-fluid'>
    <section>
        <div className="parallax-paneles">
          <h2>Paneles Solares</h2>
        </div>
        </section>
          <div className="block">
             <p className="text-justify fs-5">
                El sol puede proporcionar energía suficiente para dar electricidad a todo el planeta.
                 Pero no puede hacerlo directamente. Los paneles solares son el intermediario que hace
                  que la luz solar nos sirva de energía. Su diseño es simple, muy eficaz y permite el autoconsumo, 
                  lo que fomenta la sostenibilidad. En un futuro próximo, los paneles generarán electricidad incluso de noche.
              </p>
              </div>
                     
       
        <details className="style2">
              <summary>ver más</summary>
              <div className='block'>
                <div className='row' > 
                <div className="text-justify fs-5">
                  <p>La aplicación de paneles solares en los hogares es una forma cada vez más
                     popular de utilizar energía renovable y reducir la dependencia de fuentes
                     de energía no renovables. </p></div>
                  <div className="text-justify fs-5"> 
                  <p>¿Por qué usar paneles solares?</p>
                  <p>Pueden generar un ahorro económico significativo a lo largo del tiempo al 
                     reducir los costos de electricidad en los hogares y las empresas </p>
                  <p> Al generar su propia electricidad con paneles solares, los propietarios
                      pueden reducir drásticamente su dependencia de la red eléctrica y, por 
                      lo tanto, disminuir su factura de electricidad mensual. Cuanto más grande 
                      sea el sistema solar instalado, mayor será el ahorro potencial en la factura
                      de electricidad.</p>
                  <p> Existe la opción de conectar el sistema solar a la red eléctrica y aprovechar 
                      las tarifas de alimentación neta. Esto significa que cuando el sistema solar 
                      genera más electricidad de la que se consume en el hogar, el exceso se puede
                      enviar a la red eléctrica y el propietario recibe créditos o compensación por 
                      esa energía excedente.</p>
                </div>
                <div className='row'> 
                <img className="homeImg"
                       src="https://img.freepik.com/foto-gratis/hombre-trabajador-campo-junto-paneles-solares_1303-15597.jpg?w=996&t=st=1688413828~exp=1688414428~hmac=bc10df96c0546358987b1012ea1610b3598d0cab663db580b7821c698f4e80e0" 
                       alt="something here" />
                </div>
                </div>
              </div>
            </details>
    </div>
    </div>
  )
}

export default Paneles
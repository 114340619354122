import React from "react";
import '../css/styles.css';
const Econdo = () => {
  return (
    <div className='container-fluid'>
    <div  id='parallax-world-of-ugg'> 
    <section>
          <div className="parallax-econdo">
            <h2 >eCondominium</h2>
          </div>
        </section>
        <section>
          <div className="block">
        <div className="col">          
          <p className="text-justify fs-5">
            Es la plataforma web y móvil, 100% mexicana, para la administración
            de fraccionamientos y edificios, Generación de cargos manuales y
            automáticos, Captura de ingresos, captura de ingresos
            extraordinarios, Recargos y comisiones , Nueva versión de Estado de
            cuenta del condómino Fácil de utilizar y muy intuitivo. Movilidad a
            través de la app. Fomenta la sana convivencia. Evita largas filas en
            el acceso al condominio. Sistema integral través de plataforma web.
          </p>
        </div>
        </div>
  </section>
      <details className='style2' >
            <summary>Ver más</summary>
              <div className="block row">
              <div className="text-center fs-5">
                <p> Beneficios de usar nuestra plataforma</p>               
                <li>Fácil de utilizar y muy intuitivo. </li>
                <li>Movilidad a través de la app. </li>
                <li>Fomenta la sana convivencia. </li>
                <li>Evita largas filas en el acceso al condominio. </li>
                <li>Sistema integral través de plataforma web.</li>
                <li>Capacidad para integrar proveedores internos y externos.</li>
                <li>Fomenta una comunicación adecuada en el condominio. </li>                
              </div>
              <div className="text-justify fs-5 col">
                <p> Características</p>
                  <li>	Reservación de instalaciones o clases</li>
                  <li>	Reporte y seguimiento de incidentes</li>
                  <li>	Manejo de proveedores de servicios para condóminos </li>
                  <li>	Publicación y envío de mensajes y anuncios</li>
                  <li>	Aplicación móvil para Iphone y android</li>
                  <li>	•	Versión web para configurar y administrar la aplicación </li>
                  <li>	Control parental y de inquilinos</li>
              </div>
              <div className="text-justify fs-5 col">
                <p>Servicio de análisis, depuración y gestión de la información </p>
                  <li>	Depuración y carga de información histórica</li>
                  <li>	Depuración y carga inicial de saldos (saldo pendiente por cubrir y saldo a favor)</li>
                  <li>	Gestión de información de pagos en el sistema día a día </li>
                  <li>	Atención personalizada a dudas, aclaración de pagos y análisis de solicitudes de mejora</li>
              </div>
            
            <div className="row">           
                  <img className="homeimage"
                    src="https://images.pexels.com/photos/5077427/pexels-photo-5077427.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                     alt="something here" />
                </div>
          </div>
      </details>
    </div>
    </div>
  );
};

export default Econdo;

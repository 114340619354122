import React, { useState, useEffect }from "react";
import "../css/styles.css";
import Hex from "./Hex";
import Seguridad from "./Seguridad";
import Econdo from "./Econdo";
import Inot from "./Inot";
import Domotica from "./Domotica";
import Luminarias from "./Luminarias";
import ElConstruc from "./ElConstruc";
import Paneles from "./Paneles";
import RealVirt from "./RealVirt";
import Semaforo from "./Semaforo";
import Tinaco from "./Tinaco";
import { FaAngleUp } from "react-icons/fa";



const Tech1 = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
      window.addEventListener("scroll", () => {
          if (window.scrollY > 400) {
              setShowTopBtn(true);
          } else {
              setShowTopBtn(false);
          }
      });
  }, []);
  const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
  };
  return (
    <div>
      <div  className="container-fluid">      
        <div >  <Hex /> </div>
        <section className="parallax-containerH">
          <span></span>
          <span></span>
        <div className="row">
          
          <section id="domotica">  <Domotica /></section>
          <section id="econdo"> <Econdo /></section>
          <section id="elconstruc">  <ElConstruc /> </section>
          <section id="inot">  <Inot /> </section>
          <section id="lumin">  <Luminarias /> </section>
          <section id="panel"> <Paneles /> </section>
          <section id="realvir">  <RealVirt /> </section>
          <section id="seguridad">  <Seguridad/></section>
          <section id="semaforo">  <Semaforo /> </section>
          <section id="tinaco">  <Tinaco /> </section>
        </div>
        </section>
      
      <FaAngleUp className="icon-position icon-style" style={{display: showTopBtn ? 'block':'none'}} onClick={goToTop}/>
    
      </div>
    </div>
  );
};

export default Tech1;

import React from "react";

const Inot = () => {
  return (
    <div id="parallax-world-of-ugg"> 
    <div className="container-fluid">
      <section>
        <div className="parallax-IOT">
          <h2>Internet del Todo, "IOT"</h2>
        </div>
      </section>
      <section>
        <div className="block">
        <p className="text-justify fs-5">
          El "Internet del Todo" es un concepto que se refiere a la interconexión
          de todos los objetos y dispositivos cotidianos a través de Internet.
          que todos los objetos que usas diariamente, como tu teléfono,
          tu refrigerador, tu automóvil, tu reloj e incluso los electrodomésticos de tu hogar,
          pueden estar conectados y comunicarse entre sí a través de Internet.
        </p>
        </div>
        </section>
      </div>
      <details className="style2">
        <summary>ver más</summary>
        <div className="block">
        <div className="text-justify fs-5">
          <p> Por lo mismo nosotros ofrecemos internet para tus dispositivos, un internet
            con la capacidad de poder dar abasto para todos tus dispositivos y las conexiones
            entre ellos, por lo que tanto internet por medio de cables como la fibra óptica y
            Wi-Fi de velocidades adaptables a las necesidades de los dispositivos, tanto como
            el uso de 2.4 G para mayor alcance y mayor velocidad con la 5G   </p>
          <p>Por lo mismo nosotros ofrecemos internet para tus dispositivos con igualdad tanto a 
            la hora de descargar contenido como para subir lo que que más te gusta, un internet con 
            la capacidad de poder dar abasto para todos tus dispositivos y las conexiones entre
            ellos, incluso hasta 1 GB por segundo!, por lo que tanto internet por medio de cables como la fibra óptica y Wi-Fi
            de velocidades adaptables a las necesidades de los  dispositivos tanto como el uso
            de 2.4 G para mayor alcance y mayor velocidad con la 5G.    </p>
        </div>
        <div className="row">           
                  <img className="homeimage1"
                    src="https://img.freepik.com/vector-gratis/diseno-fondo-internet_23-2147626181.jpg?t=st=1688413432~exp=1688414032~hmac=6b7bd61c34b692655babb332f96229c78833d73fbfcdae8e57669cc90bd88c03" alt="something here" />
                </div>
        </div>
      </details>
    </div>
  );
};

export default Inot;

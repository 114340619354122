import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Tech1 from './components/pages/Tech1';
import Footer from './components/Footer';
import Nosotros from './components/pages/Nosotros';
import Contactos from './components/pages/Contactos';
import Ventas from './components/pages/Ventas';
import Proximo from './components/pages/Proximo';

//import Maps from './components/pages/Maps';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
function App() {
  return (
    <div className='fadeInUp-animation' >
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/tech' element={<Tech1/>} />
          <Route path='/nosotros' element={<Nosotros/>} />
          <Route path='/contactanos' element={<Contactos/>} />
          <Route path='/oportunidades' element={<Ventas/>}/>
          <Route path='/proximamente' element={<Proximo/>}/>
        </Routes>                    
      </Router>  
      <div className='conteiner-fluid'>     
        <Footer/>
      </div>        
    </div></div>
  );
}

export default App;

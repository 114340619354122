import React from 'react'
import '../css/styles.css';
const ElConstruc = () => {
  return (
    <div id='parallax-world-of-ugg'>
      <div className="container-fluid">
      <section>
        <div className="parallax-constr">
          <h2>Elementos de Construcción</h2>
        </div>        
      </section>
      <section>
          <div className="block">
            <p className="text-justify fs-5">
               Un elemento constructivo es cada uno de los materiales que integran una obra de construcción. 
               Se suelen clasificar en estructurales y compartimentadores. Los elementos constructivos estructurales 
               son los componentes de la cimentación, forjados, losas, pilares, etc. 
             </p>
             <p className="text-justify fs-5">
             Cada elemento de construcción tiene un propósito según a que tipo de construcción va dedicado, pero con 
             nosotros basta con comentarnos tus requerimientos y podremos realizar un plan, conseguir materiales de 
             calidad para hacer de tu hogar un sitio resistente y cómodo par vivir.
             </p>
         </div>
        </section> 
      </div>
      <details className="style2">
              <summary>ver más</summary>
              <div className='block' >
                <div className='row'> 
                <div className="text-justify fs-5">
                 <p> Entre los Elementos constructivos como los de tipo compartimentadores, se considera que son los componentes de cubiertas, fachadas, particiones interiores, etc.Estos elementos constructivos están 
                   conformados con diferentes materiales y productos elaborados. Los materiales más empleados son la piedra, metal, 
                   madera, arcilla, yeso natural, agua, etc. Los productos elaborados el yeso, cemento, hormigón, los morteros, vidrios,
                   aislantes térmicos y acústicos, productos cerámicos, plásticos, etc.   
                   </p></div>
                   <div className="text-justify fs-5"> 
                  <p>Las estructuras de nuestros materiales junto con el en el Centro de Investigación en Matemáticasse, implican un desarrollo en un sistema 
                    para hacer la optimización de estructuras utilizando la tecnología de armaduras con secciones de acero rolado en frio, con  esto se forma 
                    nuestro modelo, el cual con su optimización se logra una gran reducción del costo de los materiales a utilizar en diversidad de construcciones.
                   </p>
                   </div>
                   <div className='row'> 
                      <img className="homeImg"
                       src="https://img.freepik.com/foto-gratis/crop-architect-opening-blueprint_23-2147710985.jpg?w=996&t=st=1688413302~exp=1688413902~hmac=c4d71de647bfb3a66f53e48295957a184ebe3f84b7b1e30b39cde2efaa56cdc3" 
                       alt="something here" />
                   </div>
                </div>
              </div>
            </details>
    </div>
  )
}

export default ElConstruc
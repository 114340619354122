import React from 'react'

const Tinaco = () => {
  return (
    <div id="parallax-world-of-ugg">
    <div className='container-fluid'> 
       <section>
         <div className="parallax-tinaco">
           <h2>Tinacolimpio</h2>
         </div>
         </section>         
         <section>
              <div className="block">
                 <p className="text-justify fs-5">
                  "TINACOLIMPIO" es un dispositivo para agua que ofrece múltiples beneficios para mejorar la calidad
                  del agua almacenada y prevenir problemas de acumulación de sarro y bacterias. Es una solución
                  potencialmente útil para mantener el agua de los depósitos en buen estado y prolongar la
                  vida útil de los equipos relacionados con el suministro de agua.
               </p>
               <p className="text-justify fs-5" >
                    
                 </p>
               </div>
         </section>
        <div>
       <details className='style2' >
           <summary>ver más</summary>
             <div className='block'>
               <div className='row' > 
                <div className="text-justify fs-5">
                 <p>Las principales características y beneficios del producto son:           
                 </p>
                 <p> Prevención y eliminación gradual del sarro: Ayuda a reducir y prevenir la acumulación de
                    sarro en el tinaco y las tuberías, lo que puede prolongar la vida útil de los equipos y mejorar el flujo del agua.
                  </p>
                 <p>Desinfección del agua: Agrega cloro ionizado y oxígeno al agua para mantenerla libre de bacterias,
                    lo que contribuye a mantenerla segura y con un olor y sabor agradable.
                 </p>
                 <p>Reducción de la dureza del agua: Envuelve las partículas de sales, metales, minerales y sarro,
                    reduciendo hasta un 70% la dureza del agua, lo que evita la formación de incrustaciones calcáreas. 
                 </p>
                </div>
                <div className='row'>
                <img className="homeimage1"
                     src="https://m.media-amazon.com/images/I/61A-La4CNFL._SL1000_.jpg"
                     alt="something here" />
                  </div>
                </div>
             </div>            
       </details>  
      </div>
    </div>
   </div>
  )
}

export default Tinaco
import React from "react";
import "../css/tech.css";
import "../css/Hovero.css";
import "../css/parallax.css";

// \(≧▽≦)/
const text = "Para ver más, haz clic aquí ⬇️";
const Hex = () => {
  return (
    <div>       
      <title>Tecnologías</title>
      <div className="bodyhex">
      <section className="parallax-containerH">
        <h1 className="hextitle"> Nuestras Tecnologías</h1>
        <main className="gallery">
          <div className="gallery_item">
            <figure>
            <img className="hex" src={require("./img/1.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3> Domótica</h3>
                  <p> <a href='#domotica'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/2.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>eCondominium</h3>
               <p> <a href='#econdo'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/3.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Elementos de construcción</h3>
               <p> <a href='#elconstruc'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/4.png")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Internet del Todo</h3>
               <p> <a href='#inot'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/5.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Luminarias</h3>
               <p> <a href='#lumin'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/6.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Paneles Solares</h3>
               <p> <a href='#panel'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/7.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Realidad Virtual</h3>
               <p> <a href='#realvir'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/8.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Seguridad</h3>
               <p> <a href='#seguridad'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/9.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Semaforización</h3>
                <p> <a href='#semaforo'>{text}</a > </p>
              </div>
              </figure>
          </div>
          <div className="gallery_item">
          <figure>
            <img className="hex" src={require("./img/12.jpg")}alt="imagen aqui"/>
            <div className="capa">
               <h3>Tinacolimpio</h3>
                <p> <a href='#tinaco'>{text}</a > </p>
              </div>
              </figure>
          </div>
        </main>
        </section>
      </div>
    </div>
  );
};

export default Hex;

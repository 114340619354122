import React from 'react'

const Semaforo = () => {
  return (
  <div id="parallax-world-of-ugg">
   <div className='container-fluid'> 
      <section>
        <div className="parallax-semaforos">
          <h2>Semaforización</h2>
        </div>
        </section>         
        <section>
             <div className="block">
                <p className="text-justify fs-5">
                La semaforización es una metodología utilizada en el ámbito inmobiliario para clasificar y 
                organizar propiedades según su estado o situación. Se basa en el uso de colores asociados 
                a diferentes categorías para indicar el estado actual de cada propiedad.
              </p>
              <p className="text-justify fs-5" >
                   La semaforización facilita la gestión de propiedades al proporcionar una representación 
                   visual clara y rápida del estado de cada una. Permite a los agentes inmobiliarios tener 
                   un panorama general de su inventario y les ayuda a priorizar su atención y recursos en
                   las propiedades que están disponibles o en proceso de negociación activa
                </p>
              </div>
        </section>
       <div>
      <details className='style2' >
          <summary>ver más</summary>
            <div className='block'>
              <div className='row' > 
               <div className="text-justify fs-5">
                  <p>En la semaforización, se asignan colores específicos a cada estado de la propiedad. 
                  Por lo general, se utilizan los colores rojo, amarillo y verde, siguiendo el esquema de semáforo:              
                  </p>
                  <p> 
                  Rojo: Indica que la propiedad no está disponible, ya sea porque está vendida, alquilada o
                  no está en el mercado en ese momento.</p> 
                  <p> Amarillo: Indica que la propiedad está en proceso de negociación o trámite. Puede 
                  significar que se están llevando a cabo conversaciones con posibles compradores o inquilinos,
                  se están realizando inspecciones o se están gestionando los aspectos legales del proceso. </p>
                  <p> Verde: Indica que la propiedad está disponible y lista para ser vendida o alquilada. Es decir,
                  se encuentra en condiciones de ser mostrada a los posibles interesados y se está buscando 
                  activamente un comprador o inquilino.</p> 
               </div>
               <div className='row'>
               <img className="homeimage1"
                    src="https://img.freepik.com/foto-gratis/disparo-vertical-semaforo-numero-13-cronometro_181624-11218.jpg?w=360&t=st=1688414477~exp=1688415077~hmac=220fc2091dd68df418ee5b1a42d5265ecdaa003af775a1f2f2102ba739255fe2"
                    alt="something here" />
                 </div>
               </div>
            </div>            
      </details>  
     </div>
   </div>
  </div>
  )
}

export default Semaforo
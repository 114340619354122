import React from "react";
import swal from 'sweetalert';


const onPick = () =>{ 
  swal({
    title: 'Condominios Inteligentes',
    text: "Te deseamos un maravilloso día, no dudes en preguntar",
    confirmButtonAriaLabel: 'Thumbs up, great!',
    icon: "success",
    timer:"2000",    
  });
  }

const Proximo = () => {
  return (
    <div>
      <div className="parallax-containerH">
        <div className="proximo">
          <div className="title">
            <h1>Próximamente en</h1>
            <h1>Condominios Inteligentes</h1>
            <h1>Más beneficios</h1>
            <button  className="btn" onClick={onPick}> OK</button>
          </div>
        </div>
      </div>
    </div>
  );
  }

export default Proximo;

import React from 'react'
import "../css/styles.css";
const RealVirt = () => {
  return (
    <div id="parallax-world-of-ugg">
    <div className='container-fluid'>
    <section>
        <div className="parallax-RV">
          <h2>Realidad Virtual</h2>
        </div>
        </section> 
            <div className="block">
                <p className="text-justify fs-5">
                 La Realidad Virtual (RV) es un entorno de escenas y objetos de apariencia real 
                 —generado mediante tecnología informática— que crea en el usuario la sensación de
                 estar inmerso en él. Dicho entorno se contempla a través de un dispositivo conocido 
                 como gafas o casco de Realidad Virtual. Gracias a la RV podemos sumergirnos en desde videojuegos 
                 a lugares recreados virtualmente como si fuéramos los propios personajes, aprender a operar un corazón o mejorar la calidad
                 de un entrenamiento deportivo para obtener el máximo rendimiento.
              </p>
              <p className="text-justify fs-5">Tal vez te preguntaras es como la experiencia 360, pues la experiencia 360 utiliza fotografía
                 y video en 360 grados para permitir a los usuarios explorar virtualmente una propiedad y obtener una vista
                  completa de su interior y exterior y al igual que la realidad virtual  ofrece una visión envolvente de la propiedad, 
                  pero generalmente no permite una interacción completa con el entorno. Los usuarios pueden explorar diferentes áreas
                   y habitaciones, pero no pueden interactuar directamente con los objetos o realizar acciones dentro del entorno virtual</p>
              </div>
      <details className='style2' >
        <summary>ver más</summary>
          <div className='block' > 
            <div className='row'> 
             < div className="row map-responsive">
             <iframe width="560" height="315" src="https://www.youtube.com/embed/E8IH0XLvYVc" title="YouTube video player"
               frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                web-share" allowfullscreen> 
                </iframe>
             </div>
             <div  className="text-justify fs-5">
             <p>Se puede acceder a la experiencia 360 a través de un dispositivo como un ordenador, un teléfono
                 inteligente o una tablet, y se puede navegar utilizando movimientos de arrastrar y hacer clic.
                 ahorra tiempo tanto para los agentes inmobiliarios como para los clientes. Los agentes no necesitan 
                 organizar visitas físicas a todas las propiedades, lo que les permite ser más eficientes en su trabajo.
                  Los clientes, por su parte, pueden filtrar las propiedades y seleccionar las que más les interesen antes
                   de programar visitas en persona, optimizando su tiempo y esfuerzo. </p>
               </div>
              <div  className="text-justify fs-5">               
                <p>El uso de realidad virtual en el ámbito inmobiliario puede generar ahorros significativos 
                  en diversos aspectos
                </p>
                <p>Reducción de visitas físicas: La realidad virtual permite a los clientes realizar visitas
                  virtuales a los inmuebles desde cualquier ubicación. Esto reduce la necesidad de realizar
                  múltiples visitas físicas a diferentes propiedades, lo que implica gastos de transporte, 
                  tiempo y recursos </p>
                </div>
                <div  className="text-justify fs-5"> 
                <p>Mediante el uso de dispositivos de realidad virtual, como gafas o cascos VR, los clientes pueden
                  sumergirse en un entorno virtual que simula de manera detallada y realista los espacios de los 
                  inmuebles. Pueden percibir la escala, la distribución y los detalles de los ambientes como si 
                  estuvieran físicamente presentes, lo que les brinda una sensación de inmersión única. </p>
                <p> La realidad virtual permite a los clientes moverse y navegar de manera virtual dentro de 
                  los espacios de los inmuebles. Pueden caminar por las habitaciones, cambiar de perspectiva y
                  explorar cada rincón de forma intuitiva, como si estuvieran realizando una visita física. 
                  Esto crea una experiencia interactiva que les permite tener un mayor control y participación 
                  en la exploración de los inmuebles. </p>
              </div>
              <div className='row' >
              < div className="row map-responsive"> 
         
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Uk6f1I7AgyQ" 
             title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;
              gyroscope; picture-in-picture; web-share" allowfullscreen>

              </iframe>
              </div>
              </div>
             </div>
            </div>
        </details>
    </div>
  </div>
  )
}

export default RealVirt
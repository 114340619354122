import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Ventas = () => {
  return (
    <div>      
      <section className="parallax-containerH">        
        <h1>Anuncios</h1>
        <div className="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay>
                <div>
                    <img src={require("./img/1.jpg")}  alt='img1'/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src={require("./img/4.png")}  alt='img2'/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src={require("./img/3.jpg")}  alt='img3'/>
                     <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src={require("./img/4.png")}  alt='img3'/>
                     <p className="legend">Legend 1</p>
                </div>
          
            </Carousel>
        </div>        

      <div className='AnInfo'>   
        <h1>Anuncios</h1>
        <p> Venta/Renta  MN 2,780,000 Casa · 151m² · 3 Recámaras · 3 Estacionamientos</p>
        <p> Direccion</p>
        <h1> Detalles/Caracteristicas Generales</h1>
        <p> 136 m² Total 151 m² Construido 3 Baños 3 Estacionamientos 3 Recámaras 1 Medio baño 3 Antigüedad</p>
        <h1>Servicios incluidos</h1>
        <p> Seguridad privada Área de Juegos Infantiles</p>
        <h1>Seccion de mapa</h1>
      </div>   
      </section>
      </div>
  )
}

export default Ventas
import React from "react";

const Domotica = () => {
  return (
    
    <div className="container-fluid">
      <div id="parallax-world-of-ugg">
        <section>
          <div className="parallax-domotica">
            <h2>Domótica</h2>
          </div>
        </section>

        <section>
          <div className="block">
          <div className="text-justify fs-5 "> <p> ¿Qué es domótica? </p>
            <p>
              La domótica se refiere al uso de la tecnología para controlar y
              automatizar diversas tareas y dispositivos en su hogar. Por
              ejemplo, la iluminación, el control de la temperatura, los
              sistemas de seguridad o de entretenimiento.
            </p>
            <p >
              La domótica puede hacerle la vida más cómoda y eficiente al
              permitirle automatizar tareas rutinarias y controlar su casa desde
              cualquier lugar, mediante su teléfono, tableta o comandos de voz.
              También puede ayudarle a ahorrar energía, mejorar la seguridad del
              hogar y mejorar su calidad de vida en general.
            </p>
            </div>
          </div>
        </section>
        <details className="style2">
          <summary>Ver más</summary>
            <div className="block">
              <div className="row"> 
                <div className="col">
                  <div className="text-justify fs-5">
                    <p>Entre las muchas cosas que podemos hacer con Dómotica esta:</p>                  
                    <p>Control de temperatura</p>
                    <p>Controlamos los sistemas de aire acondicionado y calefacción de los equipos mas conocidos del mercado, a través de su dispositivo móvil o controles únicos
                      digitales. Además automatizamos el encendido, apagado o niveles de temperatura con base en ciertos eventos como horario, temperatura, calendario y poder eficientar el consumo.
                      </p>           
                    </div>       
                    <div className="text-justify fs-5">
                    <p> Control de cámaras y alarmas</p>
                    <p>A través de su celular o computadora puede visualizar todas las zonas estratégicas en donde se encuentren sus cámaras y alarmas ya instaladas, para poder garantizar la seguridad de su hogar.</p>
                    </div>
                    <div className="text-justify fs-5">
                      <p> Control de cortinas</p>
                      <p>A través de nuestras soluciones proveemos comodidad en el uso de sus persianas o cortinas, abriéndolas o cerrándolas usando su celular o control
                        central. Incluso pueden automatizarse con eventos como horario, movimiento, intensidad de la luz, etc.</p>
                  </div>                
                  <div className="col">           
                  <img className="homeImg row"
                    src="https://media.istockphoto.com/id/1132781699/es/foto/mano-que-sostiene-el-smartphone-con-la-aplicación-casera-elegante-en-la-pantalla.jpg?s=612x612&w=0&k=20&c=1ZBHiVotIpx62qL0zDf6iBa1lv3fWvcJpD1HWXVKdyU=" alt="something here" />
                </div>
               </div> 
            </div>
          </div> 
        </details>
      </div>
    </div>
  );
};

export default Domotica;

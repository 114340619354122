import React from "react";
import { Link } from "react-router-dom";
import "./css/navbar.css";

const Navbar = () => {
  return (
  <div>
    <header >
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link  to={'/'}> <img className="logo" src={require("./pages/img/OGlogo3.png")}alt="imagen aqui"/>  </Link>
            <button 
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
             >
                <span className="navbar-toggler-icon"></span>
            </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav mx-auto">
                  <li className="nav-item"> <Link className="nav-link" to={'/'}> Inicio  </Link>  </li>
                  <li className="nav-item"> <Link className="nav-link" to={'/nosotros'}> Nosotros </Link> </li>
                  <li className="nav-item"> <Link className="nav-link" to={'/tech'}> Tecnologías </Link> </li>              
                  <li className="nav-item"> <Link className="nav-link" to={'/contactanos'}> Contactanos </Link> </li>
                  <li className="nav-item"> <Link className="nav-link" to={'/proximamente'}> Próximamente </Link> </li>
                </ul>
        </div>
      </div>
    </nav>
    </header>
  </div>
  );
};

export default Navbar;
